import { Vue, Component } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
  components: {
    ChangeCardBalanceForm: () =>
      import(
        "../../../../components/ChangeCardBalanceForm/ChangeCardBalanceForm.vue"
      ),
  },
})
export default class ChangeCardBalanceMenu extends Vue {
  private showedMenu = false;
  private persistent = false;

  private get localListeners() {
    const listeners = { ...this.$listeners };

    delete listeners.success;
    delete listeners.close;

    return listeners;
  }

  private onCloseMenu<T>(value: T, callback: () => void) {
    callback();

    this.$emit("close", value);
  }

  private onSucces<T>(value: T, callback: () => void) {
    callback();

    this.$emit("success", value);
  }
}
